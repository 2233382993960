import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Grid,
    TextField,
    Autocomplete,
    FormGroup,
    FormControlLabel,
    FormControl,
    Select,
    Checkbox,
    InputLabel,
    MenuItem,
    LinearProgress,
    OutlinedInput
} from '@mui/material'
import * as Actions from '../../../actions/actions';
import * as EdgePCActions from "../../../actions/edgepcs"


const AddEditeEdgePCModal = (props) => {
    const [name, setName] = useState("");
    const [activeStatus, setActiveStatus] = useState(true);
    const [ipAddress, setIPAddress] = useState("");
    const [description, setDescription] = useState("");
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [barcodeScannerUsed, setBarcodeScannerUsed] = useState(false);
    const [ipadid, setIpadid] = useState("");
    const [validated, setValidated] = useState(false);
    const [serialNumber, setSerialNumber] = useState("" || null);
   // const [wrapperType, setWrapperType] = useState("1");
    const [isSubmitted, setIsSubmitted] = useState(false);


    const handleClose = () => {
        props.handleClose();
    }
    const handleNameChange = (event) => {
        setName(event.target.value)
    }
    const handleActiveStatusChange = (event) => {
        setActiveStatus(event.target.checked);
    }
    const handleIPAddressChange = (event) => {
        setIPAddress(event.target.value)
    }
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    }
    const handleBarcodeScannerUsedChanged = (event) => {
        setBarcodeScannerUsed(event.target.checked);
    }
    const handleSerialNumber = (event) => {
        setSerialNumber(event.target.value)
    }

    const handleWrapperType = (event) => {
       // setWrapperType(event.target.value)
    }


    const handleSubmit = () => {
        setIsSubmitted(true); props.setLoading(true);
        if (name.length == 0 || selectedApplication == null) {
            props.setLoading(false);
            return;
        }
        let edgePC = {
            "dc_id": props.selectedDC.dc_id,
            "customer_id": props.selectedCustomer.customer_id,
            "application_type_id": selectedApplication.application_type_id,
            "pc_name": name,
            "pc_short_name": null,
            "status": activeStatus ? 1 : 0,
            "ip_address": ipAddress,
            "description": description,
            "is_barcode_scanner_used": barcodeScannerUsed,
            //"wrapper_type_id":wrapperType,
            "edge_device_generated_serial_number": serialNumber ? serialNumber : null

        }
        if (props.selectedEdgePC && props.selectedEdgePC.edge_pc_id) {
            props.editEdgePC(edgePC, props.selectedEdgePC.edge_pc_id);
            props.setLoading(false);
        } else {
            props.addEdgePC(edgePC);
            props.setLoading(false);
        }
        props.handleClose(true);
    }

    useEffect(() => {
        setValidated(false);
        if (props.applicationsList.length == 0) props.fetchVORApplication();
        //props.fetchEdgeSerials();
        setIsSubmitted(false);
    }, [])
    useEffect(() => {
        selectedApplication && props.fetchEdgeSerials(selectedApplication.application_name + "_PC");
    }, [selectedApplication])
    useEffect(() => {
        console.log(props.edgeSerials)
        if (props.selectedEdgePC && props.selectedEdgePC.edge_pc_id) { //Edit PC Edge Scenario
           // setWrapperType(props.selectedEdgePC.wrapper_type_id)
            setName(props.selectedEdgePC.pc_name);
            setActiveStatus(props.selectedEdgePC.status.toString() === "1" ? true : false);
            setIPAddress(props.selectedEdgePC.ip_address);
            setDescription(props.selectedEdgePC.description);
            setBarcodeScannerUsed(props.selectedEdgePC.is_barcode_scanner_used ? props.selectedEdgePC.is_barcode_scanner_used : false);
            setSelectedApplication(props.applicationsList.find((option) => option.application_type_id === props.selectedEdgePC.application_type_id));
            setIpadid(props.selectedEdgePC.ipad_id);
            setSerialNumber(props.selectedEdgePC.edge_device_generated_serial_number ? props.selectedEdgePC.edge_device_generated_serial_number : null);
            selectedApplication && props.fetchEdgeSerials(selectedApplication.application_name + "_PC")
        }
    }, [props.selectedEdgePC]);
    useEffect(() => {
        if (props.selectedEdgePC && props.selectedEdgePC.edge_pc_id) { //Edit PC Edge Scenario
            setSelectedApplication(props.applicationsList.find((option) => option.application_type_id === props.selectedEdgePC.application_type_id));
            selectedApplication && props.fetchEdgeSerials(selectedApplication.application_name + "_PC")
        }
    }, [props.applicationsList]);

    return (
        <>
            <Dialog open={true} onClose={handleClose}
                PaperProps={{ sx: { width: '700px', minHeight: "500px" } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} style={{ marginBottom: '32px' }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    error={(isSubmitted && name.length <= 0)}
                                    id="outlined-name"
                                    label="Name*"
                                    value={name || ''}
                                    onChange={handleNameChange}
                                    helperText={"Hint: Enter your text here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal

                                    id="applicationId"
                                    options={props.applicationsList ? props.applicationsList : []}
                                    value={selectedApplication}
                                    getOptionLabel={(option) => option.application_name}
                                    renderInput={
                                        (params) =>
                                            <TextField error={(isSubmitted && !selectedApplication)} {...params} label="Application*" helperText={("Select Application Name")} />}
                                    onChange={(event, option) => {
                                        setSelectedApplication(option);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-name"
                                    label="IP Address"
                                    value={ipAddress || ''}
                                    onChange={handleIPAddressChange}
                                    helperText={"Hint: Enter the IP Address here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-name"
                                    label="Description"
                                    value={description || ''}
                                    onChange={handleChangeDescription}
                                    helperText={"Hint: Enter the description here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </FormControl>
                        </Grid>



                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="serial-number-label">Serial Number</InputLabel>
                                <Select
                                    labelId="serial-number-label"
                                    label="Serial Number"
                                    id="serial-number-label"
                                    value={serialNumber ? serialNumber : ''}
                                    onChange={handleSerialNumber}
                                >
                                    <MenuItem
                                        key={"selectSerial"}
                                        value={null}
                                    >
                                        {"No Selection"}
                                    </MenuItem>
                                    {props.selectedEdgePC && props.selectedEdgePC.edge_device_generated_serial_number &&
                                        <MenuItem
                                            key={props.selectedEdgePC.edge_device_generated_serial_number}
                                            value={props.selectedEdgePC.edge_device_generated_serial_number}
                                        >
                                            {props.selectedEdgePC.edge_device_generated_serial_number}
                                        </MenuItem>
                                    }
                                    {props.edgeSerials && props.edgeSerials.map((sNo) => (
                                        <MenuItem
                                            key={"serialNumber" + sNo}
                                            value={sNo}
                                        >
                                            {sNo}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                {ipadid ?
                                    <TextField
                                        id="outlined-name"
                                        label="iPad App id"
                                        value={ipadid || ''}
                                        onChange={(event) => {
                                            setIpadid(event.target.value);
                                        }}
                                        style={{ backgroundColor: '#fff' }}
                                        disabled={ipadid}
                                    /> : ""}
                            </FormControl>
                        </Grid>
                        {/*<Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="wrapper-type-label">Wrapper Type</InputLabel>
                                <Select
                                    labelId="wrapper-type-label"
                                    label="Wrapper Type"
                                    id="wrapper-type-labell" placeholder={"Wrapper Type"}
                                    value={wrapperType ? wrapperType : ''}
                                    onChange={handleWrapperType}
                                >
                                    <MenuItem key={"1"} value={"1"}>
                                        {"Lantech"}
                                    </MenuItem>
                                    <MenuItem key={"2"} value={"2"}>
                                        {"VORTAL"}
                                    </MenuItem>
                                    <MenuItem key={"3"} value={"3"}>
                                        {"HSW"}
                                    </MenuItem>

                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                        </Grid>*/}
                        <Grid item xs={6}>
                            <FormGroup >
                                <FormControlLabel control={<Checkbox checked={activeStatus} onChange={handleActiveStatusChange} />} label="Active Status" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={barcodeScannerUsed} onChange={handleBarcodeScannerUsedChanged} />} label="Barcode Scanner Used?" />
                            </FormGroup>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={handleSubmit} variant="contained" disableElevatio>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        selectedEdgePC: state.edgePCs.selectedEdgePC,
        applicationsList: state.edgePCs.applicationList ? state.edgePCs.applicationList : [],
        selectedDC: state.edgePCs.selectedDistributionCenter,
        selectedCustomer: state.edgePCs.selectedCustomer,
        edgeSerials: state.edgePCs.edgeSerials
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchVORApplication: () => dispatch(Actions.LOAD_VOR_APPLICATIONS()),
        addEdgePC: (pcInfo) => dispatch(EdgePCActions.ADD_EDGE_PC(pcInfo)),
        editEdgePC: (pcInfo, edgePCId) => dispatch(EdgePCActions.EDIT_EDGE_PC(pcInfo, edgePCId)),
        fetchEdgeSerials: (deviceType) => dispatch(EdgePCActions.LOAD_EDGE_SERIALS(deviceType)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditeEdgePCModal);
