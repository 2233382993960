import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

import { useTheme } from '@mui/material/styles';

let wareHouseIdUpdated = false;
const LocationManagementAddEditModal = (props) => {
    const theme = useTheme();
    const [locationName, setLocationName] = useState();
    const [locationJDENumber, setLocationJDENumber] = useState();
    const [locationId, setLocationId] = useState();
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [timezone, setTimezone] = useState('US/Central');
    const [email, setEmail] = useState();
    const [activeStatus, setActiveStatus] = useState(true);
    const [locationType, setLocationType] = useState('latitude');
    const [fullAddress, setFullAddress] = useState();
    const [warehouseId, setWarehouseId] = useState("");
    const [touched, setTouched] = useState(false);
    const setOfTimezones = ['US/Central', 'US/Eastern', 'US/Pacific', 'US/Mountain'];
    //warehouse_id
    let error1 = (locationName === "");
    let error2 = (latitude === "");
    let error3 = (longitude === "");
    let error5 = (email === "");
    let error6 = (fullAddress === "");
    let error7 = (warehouseId === "");


    let isFieldInvalid = (!locationName || !email);

    useEffect(() => {
        if ((error1 || error5) || isFieldInvalid) {
            setTouched(true);
        } else {
            setTouched(false);
        }
    }, [locationName, latitude, longitude, fullAddress, email]);

    useEffect(() => {
        if (!timezone) {
            setTimezone('US/Central')
        }
    }, [timezone])
    useEffect(() => {
        if (props.selectedData) {
            //console.log(props.selectedData.warehouse_id,warehouseId);
            setLocationId(props.selectedData.central_location_id)
            setLocationName(props.selectedData.location_name);
            setLocationJDENumber(props.selectedData.jde_location_id);
            setEmail(props.selectedData.email);
            setFullAddress(props.selectedData.address.full_address);
            if(!wareHouseIdUpdated){
                setWarehouseId(props.selectedData.warehouse_id);
            }

            setLatitude(props.selectedData.address.point_lat);
            setLongitude(props.selectedData.address.point_long);
            if (setOfTimezones.includes(props.selectedData.time_zone)) {
                setTimezone(props.selectedData.time_zone);
            } else {
                setTimezone('US/Central');
            }

            if (props.selectedData.active === 1) {
                setActiveStatus(true);
            } else {
                setActiveStatus(false);
            }
        }
    }, [props, props.selectedData]);
    console.log(timezone);
    const handleClose = () => {
        props.hideLocationModal(props.showModalType, false);
        setLocationName();
        setLocationJDENumber();
        setEmail();
        setFullAddress();
        setWarehouseId();
        setLatitude();
        setLongitude();
        setTimezone();
        wareHouseIdUpdated = false;
    }
    const handleCheckBoxChange = (event) => {
        setActiveStatus(event.target.checked)
    }
    const handleLocationNameChange = (event) => {
        setLocationName(event.target.value);
    }
    const handleLocationJDENumberChange = (event) => {
        setLocationJDENumber(event.target.value);
    }
    const handleAddressChange = (event) => {
        setFullAddress(event.target.value);
    }
    const handleWHIdChange = (event) => {
        wareHouseIdUpdated = true;
        setWarehouseId(event.target.value);
    }
    const handleLatitudeChange = (event) => {
        setLatitude(event.target.value);
    }
    const handleLongitudeChange = (event) => {
        setLongitude(event.target.value);
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handleTimezoneChange = (event) => {
        setTimezone(event.target.value);
    };
    const handleLocationTypeChange = (event) => {
        setLocationType(event.target.value);
    };
    const callUpdatedLocationList = () => {
        handleClose();
        props.callUpdatedLocationList();
    }

    useEffect(() => {
        if (props.errorResponse) {
            props.setLoading(false);
        }
    }, [props.errorResponse])

    const submitDetails = () => {
        let status = activeStatus ? 1 : 0;
        let addressTemp, latitudeTemp, longitudeTemp;

        if (!locationType || locationType === 'latitude') {
            latitudeTemp = latitude;
            longitudeTemp = longitude;
        } else {
            addressTemp = fullAddress;
        }
        if (props.showModalType === 'Add') {
            props.setLoading(true);
            props.addLocation(props.centralCustomerId, locationName, locationJDENumber, addressTemp, latitudeTemp, longitudeTemp, status, email, timezone, callUpdatedLocationList,(warehouseId?warehouseId:""));
        } else if (props.showModalType === 'Edit') {
            props.setLoading(true);
            props.editLocation(locationId, props.centralCustomerId, locationName, locationJDENumber, addressTemp, latitudeTemp, longitudeTemp, status, email, timezone, callUpdatedLocationList,(warehouseId?warehouseId:""));
        }
    }
    const getStyles = (name, personName, theme) => {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            },
        },
    };
    let applicationsList = [];
    const setApplicationsList = () => {
        let temp = [];
        for (let i in props.applicationsList) {
            temp.push(props.applicationsList[i].application_long_name);
        }
        applicationsList.push(...temp);
    };
    setApplicationsList();

    return (
        <>
            {props.showModal && <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '450px' } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent>
                    {props.errorResponse && <Alert severity="error" sx={{ marginBottom: '20px' }}>{props.errorResponse}</Alert>}
                    <div style={{ float: 'right' }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={activeStatus} onChange={handleCheckBoxChange} />} label="Active Status" />
                        </FormGroup>
                    </div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { marginTop: '20px', width: '40ch', boxShadow: 'none' },
                        }}
                        noValidate
                    >
                        <TextField
                            id="outlined-name"
                            label="Location Name*"
                            value={locationName || ''}
                            onChange={handleLocationNameChange}
                            error={error1}
                            helperText={error1 ? "Please enter a text" : "Hint: Enter your text here"}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <TextField
                            id="outlined-name"
                            label="Location JDE Number"
                            type="number"
                            value={locationJDENumber || ''}
                            onChange={handleLocationJDENumberChange}
                            helperText={"Hint: Only numbers are allowed. Please enter your Location JDE Number"}
                            inputProps={{ min: 0 }}
                            style={{ backgroundColor: '#fff' }}
                            sx={{
                                '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                },
                                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            }}
                        />
                        <TextField
                            id="outlined-name"
                            label="Email*"
                            value={email || ''}
                            onChange={handleEmailChange}
                            error={error5}
                            helperText={error5 ? "Please enter a text" : "Hint: Enter your text here"}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <Box sx={{ marginTop: '20px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Timezone*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={timezone ? timezone : 'US/Central'}
                                    label="Timezone*"
                                    onChange={handleTimezoneChange}
                                >
                                    <MenuItem value={'US/Central'}>US/Central</MenuItem>
                                    <MenuItem value={'US/Eastern'}>US/Eastern</MenuItem>
                                    <MenuItem value={'US/Pacific'}>US/Pacific</MenuItem>
                                    <MenuItem value={'US/Mountain'}>US/Mountain</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <FormControl sx={{ marginTop: '20px' }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Choose 1 type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={locationType}
                                onChange={handleLocationTypeChange}
                            >
                                <FormControlLabel value="latitude" control={<Radio />} label="Latitude/Longitude" />
                                <FormControlLabel value="address" control={<Radio />} label="Physical Address" />
                            </RadioGroup>
                        </FormControl>
                        {locationType === 'address' && <TextField
                            id="outlined-name"
                            label="Address*"
                            value={fullAddress || ''}
                            onChange={handleAddressChange}
                            error={error6}
                            helperText={error6 ? "Please enter a text" : "Hint: Enter your text here"}
                            style={{ backgroundColor: '#fff' }}
                        />}
                        {locationType === 'latitude' &&
                            <div>
                                <TextField
                                    id="outlined-name"
                                    label="Latitude"
                                    value={latitude || ''}
                                    onChange={handleLatitudeChange}
                                    error={error2}
                                    helperText={error2 ? "Please enter a text" : "Hint: Enter your text here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                                <TextField
                                    id="outlined-name"
                                    label="Longitude"
                                    value={longitude || ''}
                                    onChange={handleLongitudeChange}
                                    error={error3}
                                    helperText={error3 ? "Please enter a text" : "Hint: Enter your text here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </div>}
                        <TextField
                            id="outlined-name"
                            label="Warehouse Id"
                            value={warehouseId || ''}
                            onChange={handleWHIdChange}
                            error={error6}
                            helperText={error6 ? "Please enter a text" : "Hint: Enter your text here"}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <div style={{ float: 'right', padding: '10px' }}>
                            <Button variant="contained"
                                onClick={submitDetails}
                                disabled={touched}
                            >Save</Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        showModal: state.locationManagement.showLocationModal,
        showModalType: state.locationManagement.showModalType,
        selectedData: state.locationManagement.selectedData,
        errorResponse: state.locationManagement.error
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideLocationModal: (type, show) => dispatch(Actions.LOCATION_ADD_EDIT_MODAL(type, show)),
        addLocation: (customerId, locationName, locationJDENumber, address, latitude, longitude, status, email, timezone, callUpdatedLocationList,wareHouseId) => dispatch(Actions.CREATE_LOCATION(customerId, locationName, locationJDENumber, address, latitude, longitude, status, email, timezone, callUpdatedLocationList,wareHouseId)),
        editLocation: (locationId, customerId, locationName, locationJDENumber, address, latitude, longitude, status, email, timezone, callUpdatedLocationList,wareHouseId) => dispatch(Actions.EDIT_LOCATION(locationId, customerId, locationName, locationJDENumber, address, latitude, longitude, status, email, timezone, callUpdatedLocationList,wareHouseId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationManagementAddEditModal);